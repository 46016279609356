import { FullPageElement, html, css, formCss, highlight } from 'Elements';
import { Router } from 'Router';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { Session, Fetcher, Lang, Sleep } from 'Utils';
import App from 'App';
import validationCss from '../../private/admin/registry/tree/validation.css.js';
import { VALIDATION_STATUS_ICON } from '../../private/admin/registry/tree/config.js';
import 'Components/boss/ToastEditor.js';
import 'Components/business/select-severity.js';
import 'Components/business/select-external-sos.js';
import 'Components/business/select-classification.js';
import 'Components/business/select-registry.js';
import dayjs from 'dayjs';

// Mitre attack
// Vulnérabilité CVE

class SecurityEventCreatePage extends FullPageElement {
  static get styles() {
    return [
      super.styles,
      validationCss,
      formCss,
      css`

      .background_home {
        background-color:#164366;
        min-height:25vh;
        display:flex;
        position:relative;
      }

      .background_home_img {
        position:absolute;
        display:flex;
        min-height:25vh;
        width:100%;
        background-size: cover;
        background-image: url('assets/img/visuals/magnifier.jpg');
        opacity: 1:
      }

      .spinner_container {
        display:flex;
        justify-content:center;
        align-items:center;
        min-height:25vh;
        width:100%;
      }

      .error_container {
        padding:20px;
        text-align:center;
        font-size:1em;
        width:100%;
      }

      .error_container m-icon {
        display:block;
        text-align:center;
        font-size:60px;
        color:var(--sl-color-danger-500);
      }

      .big_title {
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: linear-gradient(to right, transparent 0%, #111111bb 20%, #111111bb 80%, transparent 100%);
        padding:10px;
        width:100%;
        color:white;
        text-transform:uppercase;
        text-shadow: 1px 1px 1px #000000;
        font-size:2em;
        text-align:center;
        display:flex;
        justify-content:center;
        align-items:top;
        flex-direction:column;
      }

      .center {
        text-align:center;
        display:block;
        padding:20px;
      }

      .flex {
        padding:20px;
        display:flex;
        gap:20px;
      }

      .flex .left {
        text-align:center;
        flex:1;
      }

      .flex .right {
        width:350px;
      }

      .flex_c2 {
        display:flex;
        gap:20px;
      }

      .labels_break {
        display:flex;
        flex-direction:row;
        align-items: flex-start;
        width:100%;
        gap:4px;
        font-size:1em;
        margin-bottom:4px;
      }

      .labels_break label {
        min-width:350px;
        white-space:nowrap;
        display:block;
        padding-right:5px;
        padding-top:2px;
      }

      .labels_break .values_icon {
        display:grid;
        grid-template-columns: 25px 1fr;
        width:100%;
        font-weight:bold;
      }

      .exts {
        display:flex;
        flex-direction:column;
        gap:15px;
        padding:5px;
      }

      .ext {
        display:flex;
        align-items:center;
        gap:10px;
        min-width:250px;
      }

      .ext img {
        height:30px;
      }

      a {
        color:var(--sl-color-primary-700);
        position:relative;
      }

      a:hover {
        text-decoration:underline !important;
      }

      a m-icon {
        font-size:1.5em;
        float:left;
        margin-left:10px;
        margin-right:10px;
      }

      .text {
        text-align:left;
        margin-left:20px;
        margin-right:20px;
      }

      sl-button {
        min-width:350px;
        font-weight:bold;
      }

      .full {
        width:100%;
        margin-left:auto;
        margin-right:auto;
      }

      sl-badge {
        float:left;
        margin-right:10px;
        font-size:1.5em;
      }

      .img_icons {
        height: 18px;
      }

      section-header {
        margin-bottom:20px;
      }

      .flex_3c {
        display:grid;
        grid-template-columns: 250px 15px 1fr;
        gap:5px;
        align-items:center;
        font-size:1em;
      }

      .flex_3c div {
        font-weight:bold;
      }

      div.icon {
        position:relative;
      }

      div.icon m-icon {
        position:absolute;
        top:0.2em;
      }

      .validation {
        font-size:15px;
      }

      .label_line {
        margin-bottom: 3px;
        display: block;
      }

      sl-select,
      select-classification,
      select-severity {
        width:335px;
      }

      .buttons {
        display:flex;
        justify-content:center;
      }

      sl-radio::part(base) {
        font-size:0.9em;
      }

      sl-checkbox::part(label) {
        font-size:0.9em;
      }


      contract-sla {
        background-color:var(--sl-panel-background-color);
        padding-top:10px;
      }

      @media (max-width: 670px) {
        .labels_break {
          flex-direction:column;
          align-items:flex-start;
        }

        .labels_break .values_icon {
          margin-bottom:10px;
        }

        .img_icons {
          width:initial;
        }

        sl-select {
          width:100%;
          margin-bottom:10px;
        }
      }
      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            main:{
              title:'Cybersecurity incidents',
              button:'Access my folder'
            },
            fields:{
              email_primary:'Primary email address',
              email_secondary:'Secondary email address',
              mobile_primary:'Primary mobile phone',
              mobile_secondary:'Secondary mobile phone',
            },
            help:{
              email_waiting:'Email address waiting for validation',
              email_validated:'Email address validated',
              mobile_waiting:'Mobile phone waiting for validation',
              mobile_validated:'Mobile phone validated',
            },
            validation:{
              MIN_LENGTH:'The value is too short ({{min}} characters minimum)',
              REQUIRED:'This field is required',
            }
          },
        },
        french:{
          translation: {
            main:{
              title:'Déclarer un évènement de sécurité',
              button1:'Déclarer un évènement de sécurité ',
              button2:'Consulter une déclaration existante',
            },
            fields:{
              email_primary:'Adresse email primaire',
              email_secondary:'Adresse email secondaire',
              email_validated:'Adresse email validée',
              mobile_primary:'Téléphone mobile principal',
              mobile_secondary:'Téléphone mobile secondaire',
            },
            help:{
              email_waiting:'Adresse email en attente de validation',
              email_validated:'Adresse email validée',
              mobile_waiting:'Mobile en attente de validation',
              mobile_validated:'Mobile validé',
            },
            validation:{
              MIN_LENGTH:'La valeur est trop courte ({{min}} caractères minimum)',
              REQUIRED:'Ce champ est obligatoire',
            }
          }
        }
      }
    ]
  }

  static get properties() {
    return {
      //userInfo: { type: Object },
      //ticket: { type: Object },
    };
  }

  constructor () {
    super();
    this.userInfo = null;
    this.userNotFoundInRegistry = false;
    this.ticket = null;
  }

  async isUserAllowed() {
    // Check if the user has been found in the registry
    // if not, the user is not allowed to declare a security event
    const response = await Fetcher.get('private/user/account/registry');
    if (!response) return;

    if (!response.ok) {
      if (response.error === 'ENOTLOGGED') {
        this.loading = false;
        this.userNotFoundInRegistry = true;
        this.requestUpdate();
        return;
      }
    }

    this.userInfo = response.data;
  }

  async connectedCallback() {
    super.connectedCallback();

    await this.isUserAllowed();
    if (!this.userInfo) return;

    // fetch info from Router;
    const ticketHash = App.config.router.location.params.hash;

    if (ticketHash) {
      const response = await Fetcher.get(`public/tickets/${ticketHash}`);
      if (response) {
        const ticket = response.data;
        this.ticket = this.formatTicketData(ticket);
      }

      const firstInput = this.qs('sl-input[name="title"]');
      setTimeout(() => {
        if (firstInput) firstInput.focus();
      }, 500);

    } else {
      /*
      setTimeout(() => {
        this.fillWithFakeValues();
      }, 0);
      */
    }

    this.requestUpdate();
  }

  fillRandom(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
  }

  formatTicketData(ticket) {
    return ticket;
  }

  fillWithFakeValues() {

    const now = dayjs().format('DD/MM/YYYY - HH:mm:ss');
    const nowDate = dayjs().format('YYYY-MM-DD');
    const nowTimeBefore = dayjs().format('HH:mm');
    const nowDateBefore = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
    const nowTimeBefore2 = dayjs().subtract(1, 'day').format('HH:mm');

    const ticket = {
      title: 'Titre evenement '+now,
      resume: 'Resume evenement '+now,
      description: 'Description evenement '+now,
      observedDate: nowDate,
      observedTime: nowTimeBefore,
      eventStartDate: nowDateBefore,
      eventStartTime: nowTimeBefore2,
      impactPersonalData: this.fillRandom(['yes','no']),
      impactProductionData: this.fillRandom(['yes','no']),
      impactProductionOff: this.fillRandom(['yes','no']),
      evalSeverity: this.fillRandom(['P0','P1','P2','P3','P4','P5']),
      assetsDescription: 'mes actifs '+now,
      ioc: 'mes ioc '+now,
      notes: 'mes notes '+now,
    };

    this.ticket = this.formatTicketData(ticket);
  }

  renderComValidationIcon(item, device, level) {
    /*
    const statusField = `validation_${device}_${level}_status`;
    const icon = VALIDATION_STATUS_ICON[device][this.item[statusField]];
    return html`<m-icon name="${icon}" color="green"></m-icon>`;
    */

    const field = `validation_${device}_${level}_status`;
    const statusIcon = VALIDATION_STATUS_ICON[device];
    const value = item[`${device}_${level}`];
    const status = item[field];

    let icon = statusIcon.undefined;
    let css = 'undefined';

    if (value) {
      icon = statusIcon[item[field] || 'not_validated'];
      css = 'not_validated';
    } else {
      return '';
    }

    css = 'validation ' + (item[field] || css);
    return html`<m-icon name="${icon}" clickable class="${css}" device="${device}" level="${level}" status="${status}" @click=${this.showValidationHelp}></m-icon>`;
  }

  showValidationHelp(ev) {
    const device = ev.target.getAttribute('device');
    const level =  ev.target.getAttribute('level');
    const status = ev.target.getAttribute('status');
    const modalValidation = this.qs('#modal-validation-help');
    modalValidation.label = this._tl(`fields.${device}_${level}`);
    modalValidation.innerHTML = this._tl(`help.${device}_${status}`);
    modalValidation.show();
  }

  renderComDevices(item, device, level) {
    const value = item[`${device}_${level}`];
    if (!value) return 'N/C';
    return value;
  }

  renderCom(item, device, level) {
    const key = `${device}_${level}`;
    const value = item[key];
    //if (!value) return '';
    return html`
      <div class="labels_break">
        <label>${this._tl(`fields.${key}`)} :</label>
        <div class="values_icon">
          <div class="icon">${this.renderComValidationIcon(this.userInfo, device, level)}</div>
          <div>${this.renderComDevices(this.userInfo, device, level)}</div>
        </div>
      </div>
    `;
  }

  renderIdentity(item) {
    const identify = `${item.gender || ''} ${item.firstname} ${item.lastname}`;
    return html`
      <div class="labels_break">
        <label>Nom / Prénom:</label>
        <div class="values_icon">
          <div class="icon"></div>
          <div>${identify}</div>
        </div>
      </div>`;
  }

  renderCompany(item) {
    if (!item.parents) return '';
    const firstStructure = item.parents[0];
    if (!firstStructure) return '';
    return html`
      <div class="labels_break">
        <label>Société :</label>
        <div class="values_icon">
          <div class="icon"></div>
          <div>${firstStructure.name}</div>
        </div>
      </div>
    `;
  }

  renderAffiliate(item) {
    if (!item.parents) return '';
    const secondStructure = item.parents[1];
    if (!secondStructure) return '';
    return html`
      <div class="labels_break">
        <label>Organisme d'affiliation :</label>
        <div class="values_icon">
          <div class="icon"></div>
          <div>${secondStructure.name}</div>
        </div>
      </div>
    `;
  }

  renderTicketDeclarant() {
    return html`
      <div>
        <section-header size="medium" micon="contacts" micon-gradient>Informations du déclarant</section-header>
        ${this.renderAffiliate(this.userInfo)}
        ${this.renderCompany(this.userInfo)}
        ${this.renderIdentity(this.userInfo)}
        ${this.renderCom(this.userInfo, 'email','primary')}
        ${this.renderCom(this.userInfo, 'email','secondary')}
        ${this.renderCom(this.userInfo, 'mobile','primary')}
        ${this.renderCom(this.userInfo, 'mobile','secondary')}
      </div>
      <br/><br/>
    `;
  }

  onCustomerChange(ev) {
    this.qs('*[name="notifInternalIds"]').nodeId = ev.target.value;
  }

  renderTicketCustomer() {
    return html`
      <div>
        <section-header size="medium" micon="contacts" micon-gradient>Client concerné</section-header>
        <select-registry name="customerId" value=${this.ticket?.customerId} selection="single-all" filter="structures" @change=${this.onCustomerChange}></select-registry>
      </div>
      <br/><br/>
    `;
  }

  renderTicketInfosEvent() {
    return html`
      <div>
        <section-header size="medium" micon="shield_question" micon-gradient>Informations concernant l'évènement de sécurité</section-header>
        <div>
          <label class="label_line">Titre court :</label>
          <sl-input size="small" clearable name="title" value="${Lang.lookup(this.ticket,'title')}" placeholder="exemples: courriel suspect, fichier suspect, activité numérique suspecte, ddos en cours ..."></sl-input>
        </div>
        <br/>
        <div>
          <label class="label_line">Résumé :</label>
          <toast-editor height="200px" name="resume" value="${Lang.lookup(this.ticket,'resume')}" placeholder="Résumé de l'évènement" hide-renderer></toast-editor>
        </div>
        <br/>
        <div>
          <label class="label_line">Description complète :</label>
          <toast-editor height="400px" name="description" value="${Lang.lookup(this.ticket,'description')}" placeholder="Description complète de l'évènement" hide-renderer></toast-editor>
        </div>
      </div>
      <br/><br/>
    `;
  }

  renderTicketHistory() {
    return html`
      <div>
        <section-header size="medium" micon="calendar_clock" micon-gradient>Horodate & historique</section-header>
        <div class="labels_break">
          <label class="label_line">Date et heure à laquelle l’évènement a été constaté :</label>
          <div class="flex_c2">
            <sl-input type="date" name="observedDate" size="small" value="${this.ticket?.observedDate}" style="width:200px"></sl-input>
            <sl-input type="time" name="observedTime" size="small" value="${this.ticket?.observedTime}" style="width:100px"></sl-input>
          </div>
        </div>
        <div class="labels_break">
          <label class="label_line">Date et heure estimées du début de l’évènement :</label>
          <div class="flex_c2">
            <sl-input type="date" name="eventStartDate" size="small" value="${this.ticket?.eventStartDate}" style="width:200px"></sl-input>
            <sl-input type="time" name="eventStartTime" size="small" value="${this.ticket?.eventStartTime}" style="width:100px"></sl-input>
          </div>
        </div>
      </div>
      <br/><br/>
    `;
  }

  onTicketImpactsChange(ev) {
    const value = ev.target.value;
    if (value !== 'yes') return;

    const selectSeverity = this.qs('*[name="evalSeverity"]');
    const severityValue = selectSeverity.value;
    if (!severityValue) {
      selectSeverity.value = 'P1';
    }
  }

  renderTicketImpacts() {
    return html`
      <section-header size="medium" micon="motion_sensor_active" micon-gradient>Impact sur le service et les données du déclarant</section-header>
      <div class="labels_break">
        <label>Impact sur des données à caractère personnel :</label>
        <sl-select size="small" name="impactPersonalData" placeholder="Sélectionner" value="${this.ticket?.impactPersonalData || 'unknow'}" @sl-change=${this.onTicketImpactsChange}>
          <sl-option value="unknow">Inconnu à ce stade de l'analyse</sl-option>
          <sl-option value="yes">Oui</sl-option>
          <sl-option value="no">Non</sl-option>
        </sl-select>
      </div>
      <div class="labels_break">
        <label>Impact sur des données de production: </label>
        <sl-select size="small" name="impactProductionData" value="${this.ticket?.impactProductionData || 'unknow'}" @sl-change=${this.onTicketImpactsChange}>
          <sl-option value="unknow">Inconnu à ce stade de l'analyse</sl-option>
          <sl-option value="yes">Oui</sl-option>
          <sl-option value="no">Non</sl-option>
        </sl-select>
      </div>
      <div class="labels_break">
        <label>Production arrêtée: </label>
        <sl-select size="small" name="impactProductionOff" value="${this.ticket?.impactProductionOff || 'unknow'}" @sl-change=${this.onTicketImpactsChange}>
          <sl-option value="unknow">Inconnu à ce stade de l'analyse</sl-option>
          <sl-option value="yes">Oui</sl-option>
          <sl-option value="no">Non</sl-option>
        </sl-select>
      </div>
      <br/><br/>
    `;
  }

  renderClassificationTreeItems(items) {
    const iconCss = 'float:left;font-size:1em;margin-top:2px;margin-right:3px;';
    return items
      .map(item => {
        
        const icon = item.parentId 
          ? html`<m-icon name="sell" style="${iconCss};color:${item.color}"></m-icon>` 
          : html`<m-icon name="label"  style="${iconCss};color:${item.color}"></m-icon>`;

        return html`
          <sl-tree-item expanded class="tree-item ${item.hidden ? 'hidden' : ''}" ?selected=${this.isSelected(item)} value="${item[this.primaryKey]}" .item=${item}>
            <span class="title">
            ${icon}
            ${highlight(Lang.lookup(item, this.displayKey), this.q)}</span>
            ${item.children?.length ? this.renderTreeItems(item.children) : ''}
          </sl-tree-item>
        `
      });
  }

  renderTicketEval() {
    return html`
      <section-header size="medium" micon="scale" micon-gradient>Evaluation de l'évènement de sécurité</section-header>
      <div class="labels_break">
        <label>Sévérité :</label>
        <select-severity size="small" name="evalSeverity" value="${this.ticket?.evalSeverity}" clearable placeholder="Sélectionner une sévérité" name="severity"></select-severity>
      </div>
      <div class="labels_break">
        <label>Classification :</label>
        <select-classification name="evalClassification" value="${this.ticket?.evalClassification}"></select-classification>
      </div>
      <br/><br/>
    `;
  }

  renderTicketAssetsDescriptionTabsUnused() {
    return html`
      <div>
        <section-header size="medium">
          Actifs concernés par l'évènement
          <img slot="left" src="assets/img/actifs.png" class="img_icons">
        </section-header>
        <div>
          <sl-tab-group>
            <sl-tab slot="nav" panel="existing">Assets déclarés</sl-tab>
            <sl-tab slot="nav" panel="assets">Description des actifs</sl-tab>

            <sl-tab-panel name="existing">
            </sl-tab-panel>

            <sl-tab-panel name="assets">
              <toast-editor height="200px" value="${Lang.lookup(this.ticket,'assetsDescription')}" name="assetsDescription" placeholder="Liste des actifs concernés si disponible et pertinent" hide-renderer></toast-editor>
            </sl-tab-panel>
        </div>
      </div>
      <br/><br/>
    `;
  }

  renderTicketAssetsDescription() {
    return html`
      <div>
        <section-header size="medium" micon="important_devices" micon-gradient>Actifs concernés par l'évènement</section-header>
        <div>
          <toast-editor height="200px" value="${Lang.lookup(this.ticket,'assetsDescription')}" name="assetsDescription" placeholder="Liste des actifs concernés si disponible et pertinent" hide-renderer></toast-editor>
        </div>
      </div>
      <br/><br/>
    `;
  }

  renderTicketSla() {
    // @TODO: une phrase plutot que ce gros pavé
    return html`
      <div>
        <section-header size="medium">
          SLA
          <m-icon slot="left" name="alarm_on" gradient class="img_icons">
        </section-header>
        <div>
          <contract-sla readOnly .value=${this.userInfo.parents[0].contract_sla}></contract-sla>
        </div>
      </div>
      <br/><br/>
    `;
  }

  onTicketTypeChange(ev) {
    this.btSubmit = this.btSubmit || this.qs('#submit');
    if (ev.target.value === 'draft') {
      this.btSubmit.variant = 'primary';
      if (this.ticket?.id) {
        this.btSubmit.innerHTML = 'Mettre à jour le brouillon';
      } else {
        this.btSubmit.innerHTML = 'Enregistrer comme brouillon';
      }
    } else {
      this.btSubmit.variant = 'danger';
      if (this.ticket?.id) {
        this.btSubmit.innerHTML = 'Mettre à jour et soumettre';
      } else {
        this.btSubmit.innerHTML = 'Soumettre immédiatement';
      }
    }
    this.qs('#submit').disabled = false;
  }

  renderTicketConfirm() {
    return html`
      <br/><br/>
      <section-header size="medium" micon="start" micon-gradient>Déclaration de l'évènement de sécurité</section-header>
      <div class="labels_break">
        <sl-radio-group name="type" @sl-change=${this.onTicketTypeChange}>
          ${this.ticket?.id
            ? html`<sl-radio value="draft">Je souhaite seulement mettre à jour ce brouillon</sl-radio>` 
            : html`<sl-radio value="draft">Je souhaite enregistrer cet évènement sous forme de brouillon afin de pouvoir le modifier avant soumission définitive</sl-radio>`
          }
          <br/>
          ${this.ticket?.id
            ? html`<sl-radio value="ticket">Je souhaite mettre à jour ET soumettre l'évènement de sécurité</sl-radio>` 
            : html`<sl-radio value="ticket">Je souhaite soumettre cet évènement sécurité immédiatement</sl-radio>`
          }
        </sl-radio-group>
      </div>
      <br/><br/>
      <div class="buttons">
        <sl-button @click="${this.submit}" id="submit" size="medium" disabled variant="primary">Continuer</sl-button>
      </div>
      <br/><br/>
    `
  }

  renderTicketNotifications() {
    return html`
      <br/><br/>
      <section-header size="medium" micon="notification_multiple" micon-gradient>Notifications</section-header>
      <box-styled variant="warning">
        <m-icon name="warning" slot="left"></m-icon>
        Lors de l'analyse de votre dossier, le service de gestion des incidents de sécurité peut être amené à notifier les autorités compétentes.
      </box-styled>
      <br/>
      <div class="labels_break">
        <label>Je souhaite notifier les autorités externes ci-dessous:</label>
        <select-external-sos name="notifExternalIds" max-options-visible="10" value=${this.ticket?.notifExternalIds} placeholder="Optionnel, sélectionnez une ou plusieurs autorités externes"></select-external-sos>
      </div>
      <div class="labels_break">
        <label>Je souhaite notifier les contacts internes ci-dessous:</label>
        <select-registry name="notifInternalIds" selection="multiple-leaf" max-options-visible="10" value=${this.ticket?.notifInternalIds}></select-registry>
      </div>
      <br/><br/>
    `;
  }

  renderTicketIOC() {
    return html`
      <div>
        <section-header size="medium" micon="action_key" micon-gradient>Indicateurs de compromission (IOC)</section-header>
        <div>
          <toast-editor height="200px" value="${Lang.lookup(this.ticket,'ioc')}" name="ioc" placeholder="Indicateur de compromission" hide-renderer></toast-editor>
        </div>
      </div>
      <br/><br/>
    `;
  }

  renderTicketVuln() {
    return html`
      <div>
        <section-header size="medium" micon="pest_control" micon-gradient>Vulnérabilités concernées (CVE)</section-header>
        <div>
          <select-search></select-search>
        </div>
      </div>
      <br/><br/>
    `;
  }

  renderTicketNotes() {
    return html`
      <div>
        <section-header size="medium" micon="note_add" micon-gradient>Notes complémentaires</section-header>
        <div>
          <toast-editor height="200px" value="${Lang.lookup(this.ticket,'notes')}" name="notes" placeholder="Notes complémentaires" hide-renderer></toast-editor>
        </div>
      </div>
    `;
  }


  errored(fieldName, msg) {
    const q = `*[name="${fieldName}"]`;
    const fieldEl = this.qs(q);
    if (!fieldEl) {
      console.error('field not found', fieldName, q);
      return;
    }
    fieldEl.setCustomValidity(msg);
    fieldEl.reportValidity();
    fieldEl.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
  }

  getFieldValue(fieldName, asArray) {
    const q = `*[name="${fieldName}"]`;
    const el = this.qs(q);
    let value;
    if (el) {
      value = el.value;
      if (value && asArray) {
        value = value.split(',');
      }
    } else {
      console.error('getFieldValue: field not found', fieldName, q);
    }

    return value;
  }

  async submit(ev) {

    const button = ev.target    
    const lg = App.config.lg;

    const payload = {
      lg,
      type: this.getFieldValue('type'),
      title:{},
      resume:{},
      description:{},
      assetsDescription:{},
      ioc:{},
      notes:{},
      impactPersonalData: this.getFieldValue('impactPersonalData'),
      impactProductionData: this.getFieldValue('impactProductionData'),
      impactProductionOff: this.getFieldValue('impactProductionOff'),
      evalSeverity: this.getFieldValue('evalSeverity'),
      evalClassification: this.getFieldValue('evalClassification'),
      observedDate: this.getFieldValue('observedDate'),
      observedTime: this.getFieldValue('observedTime'),
      eventStartDate: this.getFieldValue('eventStartDate'),
      eventStartTime: this.getFieldValue('eventStartTime'),
      notifInternalIds: this.getFieldValue('notifInternalIds',true),
      notifExternalIds: this.getFieldValue('notifExternalIds',true),
    };

    payload.title[lg] = this.getFieldValue('title');
    payload.resume[lg] = this.getFieldValue('resume');
    payload.description[lg] = this.getFieldValue('description');
    payload.assetsDescription[lg] = this.getFieldValue('assetsDescription');
    payload.ioc[lg] = this.getFieldValue('ioc');
    payload.notes[lg] = this.getFieldValue('notes');

    if (!payload.title[lg]) {
      this.errored('title', this._tl('validation.REQUIRED'));
      return;
    }

    if (!payload.resume[lg]) {
      this.errored('resume', this._tl('validation.REQUIRED'));
      return;
    }

    if (!payload.observedDate) {
      this.errored('observedDate', this._tl('validation.REQUIRED'));
      return;
    }

    if (!payload.observedTime) {
      this.errored('observedTime', this._tl('validation.REQUIRED'));
      return;
    }

    if (payload.eventStartDate) {
      if (!payload.eventStartTime) {
        this.errored('eventStartTime', this._tl('validation.REQUIRED'));
        return;
      }
    }

    let url = 'public/tickets';
    let response;
    button.loading = true;    
    if (this.ticket?.id) {
      url += `/${this.ticket.hash}`;
      payload.id = this.ticket.id;
      response = await Fetcher.put(url, payload);
    } else {
      response = await Fetcher.post(url, payload);
    }

    button.loading = false;
    if (!response) return;
    if (!response.ok) {
      if (response.error = 'EVALIDATION_FAILED') {
        const field = response.field;
        const reason = response.reason;
        let msg = this._tl(`validation.${reason}`);
        if (response.minValue) {
          msg = this._tl(`validation.${reason}`, {min: response.minValue});
        }
        this.errored(field, msg);
        return;
      }
    } else {
      if (response.data.id) {
        if (payload.type === 'draft') {
          Router.go(`/private/cus/tickets/draft?idh=${response.data.hash}`);
        }
      }
    }
  }

  renderLoading() {
    if (this.userInfo) return;
    if (this.userNotFoundInRegistry) return;
    return html`<div class="spinner_container"><sl-spinner style="font-size: 7rem;"></sl-spinner></div>`;
  }
  
  renderNonAuthenticated() {
    if (Session.isAuthenticated()) return;

    return html`
      <br/>
      <div class="left">
        <br/>
        La déclaration d'évènements de sécurité est <br/>pour le moment réservée aux utilisateurs authentifiés.<br/><br/><br/>
        <a href="/auth/login"><sl-button size="large" pill variant="primary">Connexion</sl-button></a>
      </div>
      <div class="right">
        <box-styled variant="container">
          <div style="padding:10px;">
            Si vous ne possédez pas de compte ou si vous rencontrez des difficultés pour vous connecter, notre équipe est à votre disposition.<br/><br/>
            <a href="tel:+33(0)1 83 07 00 06"><m-icon name="call"></m-icon> +33(0)1 83 07 00 06</a><br/><br/>
            <a href="mailto:csirt@sysdream.io"><m-icon name="email"></m-icon> csirt@sysdream.io</a><br/><br/>
          </div>
        </box-styled>
      </div>
    `;
  }

  isDraft() {
    return this.ticket?.type === 'draft';
  }

  renderAuthenticated() {
    if (!Session.isAuthenticated()) return;
    if (!this.userInfo) return;

    return html`
      <div class="full">
        <box-styled variant="info" size="big">
          <br/>
          ${this.ticket?.id 
            ? this.isDraft() 
              ? html`Vous êtes en train de modifier un brouillon d'évènement de sécurité.`
              : html`Vous êtes en train de modifier un ticket.`
            : html`Vous êtes sur le point de déclarer un évènement de sécurité. Remplissez avec soin le formulaire ci-dessous.`
          }
          <br/><br/>
        </box-styled>
        <br/>
        ${this.renderTicketDeclarant()}
        ${this.renderTicketCustomer()}
        ${this.renderTicketInfosEvent()}
        ${this.renderTicketHistory()}
        ${this.renderTicketImpacts()}
        ${this.renderTicketEval()}
        ${this.renderTicketAssetsDescription()}
        ${this.renderTicketIOC()}
        ${this.renderTicketVuln()}
        ${this.renderTicketNotes()}
        ${this.renderTicketNotifications()}
        
        <box-styled variant="info" size="big">
          <br/>
          Concernant le manifest ...
          <br/><br/>
        </box-styled>

        ${this.renderTicketConfirm()}
      </div>
    `;
  }

  renderUserNotInRegistry() {
    if (!Session.isAuthenticated()) return;
    if (this.userInfo) return;
    if (!this.userNotFoundInRegistry) return;
    return html`
      <div class="error_container">
        <m-icon name="scan_delete"></m-icon>
        <p>Votre compte est introuvable (registre).</p>
        <br/>
      </div>
    `
  }

  // 

  render() {
    return html`
      <div class="background_home">
        <div class="background_home_img"></div>
          <div class="big_title"><div>${unsafeHTML(this._tl('main.title'))}</div></div>
        </div>
      </div>
      <div class="flex">
        ${this.renderLoading()}
        ${this.renderNonAuthenticated()}
        ${this.renderAuthenticated()}
        ${this.renderUserNotInRegistry()}
      </div>
      <br/>

      <modal-dialog id="modal-validation-help"></modal-dialog>
      <modal-dialog id="modal-draft-saved" label="Brouillon enregistré">
        Votre brouillon a été enregistré avec succès. Vous le retrouverez dans votre liste de brouillons qui vous est propre. Vous seul pouvez le modifier et le soumettre.
        <br/><br/>
        <sl-button slot="bt2" @click=${this.goToDraft} size="medium" pill variant="primary">Accéder à la liste de mes brouillons</sl-button
      </modal-dialog>
    `;
  }

  goToDraft() {
    Router.go('/private/cus/tickets/draft');
    window.scrollTo(0, 0);
  }

  goToFolder() {
    Router.go('./create');
    window.scrollTo(0, 0);
  }

}

customElements.define('page-cti-edit', SecurityEventCreatePage);